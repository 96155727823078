import axios from 'axios'

const post=async(data)=>{
    
       let res=await axios({
            url: 'https://api-ap-northeast-1.graphcms.com/v2/ckibrbrhpi9j701xth4upbvyj/master',
            method: 'post',
            data: {
              query: `
              mutation MyMutation {
                __typename
                createUndangan(data: {${data}}) {
                  id
                }
              }
                `
            }
          })
          return res
}

export default post