import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert, Popover, Tooltip, Overlay } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { Timer } from '../components/timer'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#455B51'
let black = 'rgb(38,38,38)'
let id = 'reni-shidqi'
let inisial_co = 'Shidqi'
let inisial_ce = 'Reni'
let fmain = 'Rosemary'
let lengkap_co = (<>Shidqi Muhammad Farisan Fagmi </>)
let lengkap_ce = (<>Reni Rahmawati</>)

let bapak_co = 'Mr. Agus Wijayanto'
let ibu_co = 'Mrs. Mimin Tita Marliani'
let bapak_ce = "Mr. H. Asep Usman"
let ibu_ce = "Mrs. Hj. Jubaedah Maskan"

let ig_co = "shidqifarisan"
let ig_ce = "renirrhm"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/02/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")
let logoig = pw("asset", "logoig-green.svg")
let gmaps = "https://goo.gl/maps/kSQFwZhTe3cPnWx98"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NHFwczg2cm5zN2RhOWxnOTI3OGpjZnVwOHEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.359707431504!2d107.88406731477453!3d-7.199736894802113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68b0f3c1e14c5d%3A0xc9e111b7db2c3660!2sJl.%20Patriot%20No.12%2C%20Sukagalih%2C%20Kec.%20Tarogong%20Kidul%2C%20Kabupaten%20Garut%2C%20Jawa%20Barat%2044151!5e0!3m2!1sid!2sid!4v1607447437735!5m2!1sid!2sid"
let slide = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 1
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}${this.state.hadir?" sesi "+this.state.sesi:''}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v + ".jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1080&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                >
                    <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap" rel="stylesheet"/>
                    <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,500;0,600;1,500;1,700&display=swap" rel="stylesheet"/>
                    </Helm>

                <div id='gold5' style={{
                    backgroundColor: '#E0EBE6'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Dear :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3"
                                            style={{ fontFamily: 'Josefin Sans, sans-serif' }}>
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("asset","burung-black.svg")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'black', fontFamily: 'white' }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>

                                </Container>
                                
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "Alex" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_ce} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "Alex", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '20px', color: '#171717', fontFamily: 'Josefin Sans, sans-serif' }}>
                                                        <b>Daughter of: :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "Alex", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_co} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "Alex", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#171717', fontFamily: 'Josefin Sans, sans-serif' }}>
                                                        <b>Son of:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center p-4" style={{ color: 'white', fontFamily:'Josefin Sans, sans-serif', backgroundColor: cmain }} >
                                    
                                    <Item>
                                        <img src={pw("asset","bismillah-white.svg")}/>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3" >
                                        In the name of Allah SWT the most beneficent and the most merciful, we are pleased to share joy at the wedding of us.<br/>
                                        InsyaaAllah will be held on:

                    </p>
                                    </Item>
                                    <Item>
                                        <p style={{fontSize:'28px',fontWeight:700,fontFamily:'Dancing Script, cursive'}} >
                                            <b>
                                            Saturday January 2nd, 2021
                                            </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4 mr-md-1 p-3" style={{ color: cmain,backgroundColor:'white'}}>
                                            <b style={{fontSize:'28px',fontWeight:700,fontFamily:'Dancing Script, cursive'}}>WEDDING AKAD</b><br />
                                            <span className=" fs16">
                                                    <br/>
                                                08.00 - 09.00 WIB
                                             </span>
                                            
                                        </p>
                                        
                                        <p className="fs20 col-sm-4 ml-md-1 p-3" style={{ color: cmain,backgroundColor:'white' }}>
                                            <b style={{fontSize:'28px',fontWeight:700,fontFamily:'Dancing Script, cursive'}}>WEDDING RECEPTION</b><br />

                                            <span className="cblack fs16">
                                            <br/>
                                                Sesi 1 : 11.00 - 12.30 WIB<br />
                                                Sesi 2 : 13.00 - 15.00 WIB
                                               </span>


                                        </p>
                                    </Item>

                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b style={{fontSize:'36px',fontWeight:700}} 
                                            className="pb-3">GEDUNG INTAN BALAREA</b>
                                            <br />
                                            Jl. Patriot No. 12-14, Sukagalih<br/>
Kecamatan Tarogong Kidul, Kabupaten Garut, Jawa Barat

                                            
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <Timer cmain={cmain} waktunikah={waktunikah} reni/>

                                    <Item>
                                        <p className="fs16 py-3">
                                        Is an honor and a joy for us if Mr./Mrs./Brother/Sister was pleased to attend and give a prayer of blessing to both Bride and Groom families .

                                        </p>
                                    </Item>

                                </Container>
                                <Container className="text-center dinny py-3" >
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px', fontFamily: 'Josefin Sans, sans-serif'}}>
                                            <h1 className="fs30"
                                                style={{

                                                    fontFamily: 'Adine-Kirnberg-Alternate',
                                                    color: cmain,
                                                    fontSize: '72px',
                                                    fontWeight:700
                                                }}>
                                                Special Conditions<br/>
For our Safety and Health


                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                    Regarding this current COVID-19 pandemic, in order to maintain physical distancing that is currently being applied, unfortunately, we are only welcoming the invited guest. Thank you for your understanding.
                          </p>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw(id, 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                        ALWAYS PUT<br/>
                                                        ON A MASK
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw(id, 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                        ALWAYS USE<br/>HANDSANITIZER
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw(id, 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                        PHYSICAL<br/>DISTANCING
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw(id, 'salaman.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                        DON’T SHAKING<br/>HAND
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>



                                <Container fluid >
                                    <Container className=" px-md-5 col-md-10 px-3"
                                        style={{ color: cmain,fontFamily: 'Josefin Sans, sans-serif' }}
                                    >

                                        <Row className="position-relative px-2 py-3">
                                            <h1 className="peri w-100 text-center" style={{fontWeight:700}}>
                                                Our Love Story
                                            </h1>
                                            <Container id="story-reni" className="p-0">
                                                <div className="container p-0">

                                                    <ul className="timeline">
                                                        {[
                                                            (<><b>First Met (2014)</b><br />
                                                            The meeting came as acts of God occur. Love begins to grow along with the frequency of continuous meetings. Until finally a love relationship exists between two human beings.                                                            
                                                                    </>),
                                                            (<><b>The Date (2015 – 2017)</b><br />
                                                            College period becomes a medium for both people to share love and get to know the character more deeply.
                                                            </>),
                                                            (<><b>Dreams and Relationship (2017)</b><br />
                                                            To pursue dreams and a future after graduation, distance becomes an obstacle for both people to remain committed to keeping the relationship going.
                                                            </>),
                                                            (<><b>Hard Moment (2018 – 2019)</b><br />
                                                            Distance is an obvious problem. Selfishness, emotions, distrust, stubbornness become other obstacles as time passes. The point where both people are concious of the existing obstacles is a test of a relationship to move to the next level
                                                            </>), (<><b>Commitment (2020)</b><br />
The 6 years that the relationship was established, various kinds of obstacles were passed together, finally both of the people committed to marriage
</>),
                                                            (<><b>Wedding Day (January 2nd, 2021)</b><br />
                                                            Today is the day when two people are united in sacred promises before Allah SWT and their families. The two men promised to love each other until the end of their lives
                                                            </>)
                                                        ].map((v, i) => {

                                                            return (

                                                                <li className={i % 2 ? "timeline-inverted" : ''} >
                                                                    <div className="timeline-badge d-none d-md-block" style={{ backgroundColor: '#FE646F' }}>
                                                                        <img src={pw("asset", "heart.svg")} style={{ height: '35px', witdh: '35px' }} />
                                                                    </div>
                                                                    <Col xs={12} md={5} className="timeline-panel" style={{ backgroundColor: 'white' }}>
                                                                        <div className="timeline-body">
                                                                            <p>{v}</p>
                                                                        </div>
                                                                    </Col>
                                                                </li>

                                                            )
                                                            i++
                                                        })}
                                                    </ul>
                                                </div></Container>
                                        </Row>
                                    </Container>


                                </Container>



                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                <h1 className="peri w-100 text-center" style={{fontWeight:700,color:cmain}}>
                                Our Love Memories
                                            </h1>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain, }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontFamily: 'Josefin Sans, sans-serif' }}>
                                                        50.000 years before the sky was introduced to the sea, Allah wrote down your name next to me.<br /><br />
No matter how long it takes, how far we go, how tough it seems, then finally we find each other.

</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Attending</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Not Attending</label>
                                                                    </div>
                                                                    {!hadir ? false : (
                                    <>  <Alert variant='dark col-12 mr-4 '>
                                      <p className="f-14">
                                      Please select the arrival Schedule:
                                      </p>
                                      <div onClick={() => {
                                        this.setState({ sesi: 1 })
                                      }
                                      }
                                      className="pl-5">
                                        <input type="radio" id="featured-3" name="sesi" checked={sesi==1 ? true : false} />
                                        <label for="featured-3">
                                        
                                        1. Akad</label>
                                      </div>
                                      <div onClick={() => {
                                        this.setState({ sesi: 2 })
                                      }
                                      } className="pl-5">
                                        <input type="radio" id="featured-4" name="sesi" checked={sesi==2 ? true : false} />
                                        <label for="featured-4"

                                        >2. Reception Session 1</label>
                                      </div>
                                      <div onClick={() => {
                                        this.setState({ sesi: 3 })
                                      }
                                      } className="pl-5">
                                        <input type="radio" id="featured-5" name="sesi" checked={sesi==3 ? true : false} />
                                        <label for="featured-5"

                                        >3. Reception Session 2</label>
                                      </div>
                                      <p className="f-14">
                                      Invited guests are expected to attend according to a predetermined session
                                      </p>
                                    </Alert> 
                                    </>

                                  )}

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

